import React from "react"
import PropTypes from "prop-types"
import cs from "classnames"
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import YouTube from "react-youtube"

export default class VideoButton extends React.Component {
  static propTypes = {
    videoId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    secondary: PropTypes.bool,
  }

  static defaultProps = {
    secondary: false,
  }

  state = {
    modal: false,
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal })
  }

  render() {
    return (
      <div className={"row py-3"}>
        <div className={"col"}>
          <Button
            className={cs("btn btn-lg w-100", { "btn-secondary": this.props.secondary, "btn-outline-primary": !this.props.secondary })}
            onClick={this.toggle}
          >
            {this.props.label}
          </Button>


          <Modal isOpen={this.state.modal} toggle={this.toggle} size={"lg"} fade={false}>
            <ModalHeader toggle={this.toggle}>{this.props.label}</ModalHeader>
            <ModalBody>
              <div className={"text-center"}>
                <YouTube videoId={this.props.videoId} opts={{width: '100%'}}/>
              </div>
            </ModalBody>
          </Modal>
        </div>

        {false &&
        <div className={"col d-lg-none"}>
          <a
            href={`https://youtube.com/watch?v=${this.props.videoId}`} target={"_blank"}
            rel={"nofollow noreferrer"}
            className={cs("btn btn-lg w-100", { "btn-outline-secondary": this.props.secondary, "btn-outline-primary": !this.props.secondary })}
          >
            {this.props.label}
          </a>
        </div>
        }
      </div>
    )
  }
}
